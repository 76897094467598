import { stringify } from 'qs'
import axios from 'axios'

export const apiHost = process.env.REACT_APP_API_HOST
export const menuSyncApiHost = process.env.REACT_APP_MENU_SYNC_API_URL
export const menuGenApiHost = process.env.REACT_APP_MENU_GENERATE_API_URL
export const syncJobPollApiHost = process.env.REACT_APP_SYNC_JOB_POLL_API_URL
export const generateJobPollApiHost = process.env.REACT_APP_GENERATE_JOB_POLL_API_URL


export const fetcher = (args) => {
  let url
  let params
  let finalUrl
  if (!Array.isArray(args)) {
    finalUrl = args
  } else {
    ;[url, params] = args

    // Note: new URLSearchParams was not used because it does not support nested objects in params
    // Required for filters
    const queryParams = stringify(params)
    finalUrl = `${url}?${queryParams}`
  }

  return axios.get(finalUrl).then(res => res.data)
}
