import { Alert, Empty, Form, Input, Modal, Pagination, Spin } from "antd";
import { useState } from "react";
import useMedia from "../../hooks/useMedia";

function MediaPicker({
  targetField = null,
  isOpen = false,
  onSelect = () => {},
  onCancel = () => {},
}) {
  const [allParams, setAllParams] = useState({ take: 10, skip: 0 });
  const { images, meta, isError, isLoading } = useMedia(allParams);
  const [selectedMedia, setSelectedMedia] = useState({});

  const [form] = Form.useForm();

  if (isError) {
    return (
      <Alert message="Error" description="Cannot load images" type="error" />
    );
  }

  const onSubmit = (values) => {
    setAllParams({ ...allParams, ...values });
    setSelectedMedia({});
  };

  return (
    <Modal
      title={`Media Picker - ${targetField}`}
      open={isOpen}
      onOk={() => onSelect(targetField, selectedMedia.url)}
      onCancel={onCancel}
      width={600}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item name="filters[posPlu]" className="mt-6" label="POS PLU">
          <Input.Search
            allowClear
            placeholder="Enter the POS PLU and press enter to search"
          />
        </Form.Item>
      </Form>
      <div className="h-96 overflow-y-auto">
        <div className="grid gap-2 grid-cols-4">
          {isLoading && (
            <div className="flex justify-center items-center col-span-4">
              <Spin />
            </div>
          )}

          {images?.map((image) => (
            <div
              key={image.id}
              className={`relative flex flex-col align-middle justify-center border rounded p-1 hover:border-2 hover:border-yellow-300 ${
                selectedMedia?.id === image.id
                  ? "border-yellow-300 border-2 "
                  : ""
              } `}
              onClick={() => setSelectedMedia(image)}
            >
              <img src={image.url} alt={image.id} />
              {selectedMedia?.id === image.id && (
                <div class="absolute top-0 right-0 bg-white p-2 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-green-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="6"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              )}
            </div>
          ))}
          {images.length === 0 && !isLoading && (
            <div className="flex justify-center items-center col-span-4">
              <Empty />
            </div>
          )}
        </div>
      </div>
      <Pagination
        total={meta?.totalRows}
        current={meta?.current}
        pageSize={meta?.limit}
        showSizeChanger={false}
        onChange={(page, pageSize) => {
          setAllParams({ ...allParams, skip: (page - 1) * pageSize });
        }}
        onShowSizeChange={(page, pageSize) => {
          setAllParams({ ...allParams, take: pageSize });
        }}
      />
    </Modal>
  );
}

export default MediaPicker;
