import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/sections`;

export const addSection = async (sectionPayload) => {
  return axios.post(baseUrl, sectionPayload);
}

export const updateSection = async (id, sectionPayload) => {
  return axios.put(`${baseUrl}/${id}`, sectionPayload);
}

export const fetchSection = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteSection = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}