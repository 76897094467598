import { Col, Row, Typography } from "antd";
import React from "react";

function HomePage() {
  return (
    <div className="mt-32 container mx-auto">
      <h2 level={2} className="font-light text-3xl">
        Hi 👋🏻, Welcome to Menu Gen Portal
      </h2>
      <Row gutter={12}>
        <Col span={12} className="text-xl">
          <Typography.Title level={4} className="mt-6">
            What is Menu Gen Portal?
          </Typography.Title>
          <p>
            Menu Gen Portal is a platform that enables you to sync your menu
            with the POS system and generate the menu items.
          </p>
          <p>
            It is a simple and easy to use platform that helps you to manage
            your menu items.
          </p>
          <h3 className="mt-10 mb-2 font-semibold text-xl">How it works?</h3>
          <ol className="list-decimal ml-6">
            <li>
              Add Category. Goto Data Sources &rarr; Categories &rarr; Add
              Categoty
            </li>
            <li>
              Sync &amp; Generate. Goto Sync &amp; Generate &rarr; Step By Step
            </li>
          </ol>
        </Col>
        <Col span={12}>
          <img src="/home-illustration.svg" className="opacity-50" />
        </Col>
      </Row>
    </div>
  );
}

export default HomePage;
