import React, { useState } from "react";
import { Alert, Table, Typography } from "antd";
import usePosMenus from "../../hooks/usePosMenus";

function PosMenuList() {
  const [allParams, setAllParams] = useState({ page: 0, pageSize: 10 });
  const { posMenus, meta, isLoading, isError } = usePosMenus(allParams);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Store Id",
      dataIndex: "storeId",
      key: "storeId",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  if (isError)
    <Alert
      message="Error"
      description="Error fetching data"
      type="error"
      showIcon
    />;

  return (
    <>
      <Typography.Title level={3}>POS Menu</Typography.Title>
      <Table
        dataSource={posMenus}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        pagination={{
          total: meta?.totalRows,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          },
        }}
        footer={() => (
          <Typography.Title level={5}>
            Total Records: {meta?.totalRows || "--"}
          </Typography.Title>
        )}
      />
    </>
  );
}

export default PosMenuList;
