import React from "react";
import ChannelsList from "../components/Channel/ChannelsList";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function ChannelsListPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/channels">Channels</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <ChannelsList />
    </div>
  );
}

export default ChannelsListPage;
