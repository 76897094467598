import { Avatar, Tooltip } from "antd";
import dayjs from "dayjs";
import { forwardRef } from "react";

const Job = forwardRef(
  ({ id, withOpacity, isDragging, style, ...props }, ref) => {
    const inlineStyles = {
      // opacity: withOpacity ? "0.5" : "1",
      cursor: isDragging ? "grabbing" : "grab",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style,
    };

    const { job } = props;

    return (
      <div ref={ref} style={inlineStyles} {...props}>
        <div
          key={job.startTime}
          className="bg-blue-50 p-1 border border-l-4 rounded border-blue-200 my-1"
        >
          <p className="text-xs m-0">
            {dayjs(job.startTime).format("HH:mm")} -{" "}
            {dayjs(job.endTime).format("HH:mm")}
          </p>
          <p className="text-xs m-0">{job.type}</p>
          <Avatar.Group
            maxCount={3}
            size={"small"}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
          >
            {props.clients.map((client) => {
              return (
                <Tooltip title={client.name} key={client.id}>
                  <Avatar src={client.avatar} size={"small"} />
                </Tooltip>
              );
            })}
          </Avatar.Group>
        </div>
      </div>
    );
  }
);

export default Job;
