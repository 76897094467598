import { Alert, Button, Form, InputNumber, Modal, Select, Table } from "antd";
import { useEffect, useState } from "react";

import {
  fetchMenuContainerAttributes,
  updateMenuContainerAttributes,
} from "../../services/http/menu-container-attributes.http";
import {
  filterOption,
  openNotification,
  selectOptionsMapper,
} from "../../utils";
import { NotificationType } from "../../constants";
import usePosMenus from "../../hooks/usePosMenus";
import useMenuContainerAttributes from "../../hooks/useMenuContainerAttributes";

function MenuContainerAttributesList({ container }) {
  const [form] = Form.useForm();
  const [id, setId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [allParams, setAllParams] = useState({
    take: 10,
    skip: 0,
    "filters[menuContainerId]": container?.id,
  });

  useEffect(() => {
    setAllParams({
      ...allParams,
      "filters[menuContainerId]": container?.id,
    });
  }, [container]);

  const { posMenus } = usePosMenus({
    take: 1000,
    skip: 0,
  });

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  const { menuContainerAttributes, meta, isLoading, error, revalidate } =
    useMenuContainerAttributes(allParams);

  const onEdit = async (id) => {
    setId(id);
    const { data } = await fetchMenuContainerAttributes(id);
    form.setFieldsValue(data);
    showModal();
  };

  const columns = [
    {
      key: "posMenu",
      title: "POS Menu",
      dataIndex: ["posMenu", "name"],
    },
    {
      key: "price",
      title: "Price",
      dataIndex: "price",
    },
    {
      key: "Actions",
      title: "Actions",
      width: "10%",
      render: (record) => (
        <Button type="link" onClick={() => onEdit(record.id)}>
          Edit
        </Button>
      ),
    },
  ];

  const onSubmit = async (values) => {
    try {
      setIsProcessing(true);
      await updateMenuContainerAttributes(id, values);
      openNotification(NotificationType.Success, "Updated successfully");
    } catch (error) {
      openNotification(
        NotificationType.Error,
        "Cannot be updated",
        error.response.data.message
      );
    } finally {
      setIsProcessing(false);
      hideModal();
      revalidate();
    }
  };

  return (
    <div>
      <Alert
        className="mb-4"
        type="info"
        showIcon
        message={
          <div>
            Container Prices for: <strong>{container?.name}</strong>
          </div>
        }
        description={
          "This container has price overrides for the rows shown below."
        }
      />

      <Modal
        title="Add Attributes"
        open={isModalVisible}
        onOk={form.submit}
        onCancel={hideModal}
        okButtonProps={{ loading: isProcessing }}
      >
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Form.Item
            label="POS Menu"
            name={"posMenuId"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={selectOptionsMapper(posMenus, true)}
              filterOption={filterOption}
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Price ($)"
            name={"price"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber min={0} max={100} className="w-20" />
          </Form.Item>
        </Form>
      </Modal>

      <Table
        dataSource={menuContainerAttributes}
        columns={columns}
        loading={isLoading}
        rowKey={(record) => record.id}
        pagination={{
          total: meta?.totalRows,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          },
        }}
      />
    </div>
  );
}

export default MenuContainerAttributesList;
