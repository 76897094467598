import React from "react";
import { WorkflowView } from "../components/Workflow/WorkflowView";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function WorkflowPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/workflow">Sync &amp; Generate</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <WorkflowView />
    </div>
  );
}

export default WorkflowPage;
