import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/menu-tags`;

export const addMenuTag = async (menuTagPayload) => {
  return axios.post(baseUrl, menuTagPayload);
}

export const updateMenuTag = async (id, menuTagPayload) => {
  return axios.put(`${baseUrl}/${id}`, menuTagPayload);
}

export const fetchMenuTag = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteMenuTag = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}