import axios from "axios";
import { syncJobPollApiHost, generateJobPollApiHost, menuGenApiHost, menuSyncApiHost } from "../../utils/http";

export const generateMenu = async (payload) => {
  return axios.post(menuGenApiHost, payload);
}

export const syncMenu = async (payload) => {
  return axios.get(menuSyncApiHost, { params: payload });
}

export const pollJob = async (jobId, type = 'menu') => {
  const host = type === 'menu' ? generateJobPollApiHost : syncJobPollApiHost;
  const url = `${host}/${type}/jobs`
  return axios.get(`${url}/${jobId}`);
}

