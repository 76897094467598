import { notification } from 'antd'

const localeCurrencyCodeMapping = {
  'en-AU': 'AUD',
  'en-US': 'USD',
  'en-IN': 'INR'
}

const getCurrencyCode = (locale) => {
  return localeCurrencyCodeMapping[locale]
}

const selectedLocale = process.env.NEXT_PUBLIC_LOCALE || 'en-AU'

export const selectedLocalCountryCode = process.env.NEXT_PUBLIC_LOCALE_COUNTRY || 'us'

export const currencyFormat = (amount) => {
  if (!amount) {
    return
  }

  return new Intl.NumberFormat(selectedLocale, {
    style: 'currency',
    currency: getCurrencyCode(selectedLocale)
  })
    .format(amount)
    .replace('.00', '')
}

export const percentageFormat = (amount) => {
  if (!amount) {
    return
  }

  return `${amount}%`
}


export function trimEndingSlash(str = '') {
  return str.replace(/\/$/, '')
}


export const openNotification = (type, message, description) => {
  notification[type]({
    message,
    description,
    placement: 'bottom',
  });
}

export const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export function selectOptionsMapper(arrOfObjects, shouldAddIdInName = false) {
  return arrOfObjects.map((obj) => {
    return { value: obj.id, label: !shouldAddIdInName ? obj.name : `${obj.name} (${obj.id})` };
  });
}
