import { Alert, Button, Card, Form, Input, Select } from "antd";
import React, { useState } from "react";
import {
  fetchMenuContainerProducts,
  renameMenuContainerProduct,
} from "../services/http/menu-container-product.http";
import { openNotification } from "../utils";
import { NotificationType } from "../constants";
import { fetchMenuContainers } from "../services/http/menu-container.http";

function RenameProduct() {
  const [form] = Form.useForm();
  const [existingNameOptions, setExistingNameOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const onSubmit = async (values) => {
    const { existingName, containerIds, newName, imageTop, imageAngle } =
      values;

    if (!newName && !imageTop && !imageAngle) {
      openNotification(
        NotificationType.Error,
        "Please provide at least one field to update in Destination Product"
      );
      return;
    }

    try {
      setIsProcessing(true);
      await renameMenuContainerProduct(
        existingName,
        containerIds,
        newName,
        imageTop,
        imageAngle
      );
      openNotification(
        NotificationType.Success,
        "Menu Container Product saved successfully"
      );
    } catch (error) {
      openNotification(
        NotificationType.Error,
        "Menu Container Product could not be saved"
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const onContainerSearch = async (val) => {
    const fields = ["id", "name", "posPlu"];
    const { data: containers } = await fetchMenuContainers(val, fields);
    setOptions(
      containers?.data?.map((d) => ({
        key: d.id,
        value: d.id,
        label: d.name,
      }))
    );
  };
  const onExistingProductSearch = async (val) => {
    const fields = ["id", "name"];
    const response = await fetchMenuContainerProducts(val, fields, "name");
    if (!response?.data?.data) return;

    setExistingNameOptions(
      response?.data?.data?.map((d) => ({
        key: d.id,
        value: d.name,
        label: d.name,
      }))
    );
  };

  return (
    <div className="container mx-auto w-[500px]">
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Card title="Source Product" className=" mx-auto">
          <Alert
            description="If you do not select the Menu Containers, it will rename the product globally."
            showIcon
            type="warning"
            className="mb-4"
          />
          <Form.Item
            label="Existing Name"
            name={"existingName"}
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Start typing to search for existing product name to rename"
              allowClear
              showSearch
              options={existingNameOptions}
              onSearch={onExistingProductSearch}
              filterOption={false}
            />
          </Form.Item>
          <Form.Item label="Menu Containers" name="containerIds">
            <Select
              placeholder="Start typing to search for existing container name to rename"
              mode="multiple"
              options={options}
              onSearch={onContainerSearch}
              filterOption={false}
            />
          </Form.Item>
        </Card>
        <Card title="Destination Product" className="w-[500px] mx-auto my-5">
          <Form.Item label="New Name" name={"newName"}>
            <Input />
          </Form.Item>
          <Form.Item label="Top Image" name={"imageTop"}>
            <Input />
          </Form.Item>
          <Form.Item label="Angle Image" name={"imageAngle"}>
            <Input />
          </Form.Item>
        </Card>
        <Button type="primary" htmlType="submit" loading={isProcessing}>
          Update
        </Button>
      </Form>
    </div>
  );
}

export default RenameProduct;
