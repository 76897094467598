import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import MediaCenterList from "../components/MediaCenter/MediaCenterList";

function MediaCenterPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/media-center">Media Center</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <MediaCenterList />
    </div>
  );
}

export default MediaCenterPage;
