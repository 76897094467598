import {
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  Form,
  Empty,
  Button,
  Skeleton,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { updateMenuContainer } from "../../services/http/menu-container.http";
import usePosMenus from "../../hooks/usePosMenus";
import useMenuContainers from "../../hooks/useMenuContainers";
import useMenuContainer from "../../hooks/useMenuContainer";
import { filterOption, openNotification } from "../../utils";
import useMenuTags from "../../hooks/useMenuTags";
import { upsertMenuContainerTags } from "../../services/http/menu-container.http";
import { NotificationType } from "../../constants";
import MediaPicker from "../MediaCenter/MediaPicker";
import MenuContainerOverridesList from "./MenuContainerOverridesList";
import MenuContainerAttributesList from "./MenuContainerAttributesList";
import { PictureOutlined, UploadOutlined } from "@ant-design/icons";

function MenuContainerFormModal({ containerId, isOpen, onClose, onSave }) {
  const [form] = Form.useForm();
  const [tagsForm] = Form.useForm();
  const { menuContainer } = useMenuContainer(containerId);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMediaPickerOpen, setIsMediaPickerOpen] = useState({
    imageTop: false,
    imageAngle: false,
  });
  const [container, setContainer] = useState();
  const [containerTags, setContainerTags] = useState();
  const { menuContainers: parentMenuContainers } = useMenuContainers({
    take: 1000,
    skip: 0,
  });
  const { posMenus } = usePosMenus({
    take: 1000,
    skip: 0,
  });

  const { menuTags } = useMenuTags({
    take: 1000,
    skip: 0,
    sortBy: "value",
  });

  useEffect(() => {
    if (menuContainer) {
      form.setFieldsValue(menuContainer);
      setContainer(menuContainer);
      tagsForm.setFieldsValue({
        menuTags: menuContainer.menuTags.map((mt) => mt.id),
      });
      setContainerTags(menuContainer.menuTags);
    }
  }, [menuContainer]);

  const onSubmit = async (values) => {
    try {
      setIsProcessing(true);
      await updateMenuContainer(containerId, values);
      onSave();
      setIsProcessing(false);
      openNotification(
        NotificationType.Success,
        "Menu Container saved successfully"
      );
      onClose();
    } catch (error) {
      setIsProcessing(false);
      openNotification(
        NotificationType.Error,
        "Menu Container could not be saved"
      );
    }
  };

  const onTagsFormSubmit = async (values) => {
    try {
      setIsProcessing(true);
      await upsertMenuContainerTags(containerId, { menuTags: values.menuTags });
      setIsProcessing(false);
      openNotification(NotificationType.Success, "Tags saved successfully");
    } catch (error) {
      setIsProcessing(false);
      openNotification(NotificationType.Error, "Tags could not be saved");
    }
  };

  const onImageSelect = (field, mediaPath) => {
    console.log({ field, mediaPath });
    form.setFieldsValue({ [field]: mediaPath });
    setIsMediaPickerOpen(false);
  };

  const openMediaPicker = (field) => {
    setIsMediaPickerOpen({ ...isMediaPickerOpen, [field]: true });
  };

  const closeMediaPicker = (field) => {
    setIsMediaPickerOpen({ ...isMediaPickerOpen, [field]: false });
  };

  return (
    <Modal
      title="Menu Container"
      open={isOpen}
      width={1000}
      footer={null}
      onCancel={onClose}
      okButtonProps={{ loading: isProcessing }}
      destroyOnClose
      centered
    >
      <div>
        <Tabs
          defaultActiveKey="1"
          style={{
            marginBottom: 32,
          }}
          type="card"
          items={[
            {
              key: "1",
              label: "Menu Container",
              children: (
                <div>
                  <Row gutter={16}>
                    <Col span={17}>
                      <Form form={form} layout="vertical" onFinish={onSubmit}>
                        <Form.Item
                          label="Name"
                          name="name"
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                          <Input />
                        </Form.Item>
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              label="Parent Container"
                              name="parentContainerId"
                            >
                              <Select
                                disabled
                                options={parentMenuContainers?.map(
                                  (parentContainer) => ({
                                    value: parentContainer.id,
                                    label: parentContainer.name,
                                  })
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="POS Menu"
                              name="posMenuId"
                              rules={[{ required: true }]}
                            >
                              <Select
                                disabled
                                options={posMenus?.map((posMenu) => ({
                                  value: posMenu.id,
                                  label: posMenu.name,
                                }))}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={6}>
                            <Form.Item
                              label="POS PLU"
                              name="posPlu"
                              rules={[{ required: true }]}
                            >
                              <Input className="w-full" disabled />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label="Display Order"
                              name="displayOrder"
                            >
                              <InputNumber className="w-full" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label="Active?"
                              name="isActive"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={8} className="mb-5">
                          <Col span={17}>
                            <Form.Item
                              label="Top Image"
                              name="imageTop"
                              className="mb-1"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={1} className="pt-7">
                            <Button
                              type="default"
                              onClick={() => openMediaPicker("imageTop")}
                              icon={<PictureOutlined />}
                            />
                            <MediaPicker
                              targetField="imageTop"
                              isOpen={isMediaPickerOpen.imageTop}
                              onSelect={onImageSelect}
                              onCancel={() => closeMediaPicker("imageTop")}
                            />
                          </Col>
                          <Col span={4} className="ml-4">
                            {container?.imageTop ? (
                              <img
                                src={container.imageTop}
                                width={60}
                                alt={container.name}
                              />
                            ) : (
                              <Empty
                                description=""
                                imageStyle={{ height: 60 }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col span={17}>
                            <Form.Item
                              label="Angle Image"
                              name="imageAngle"
                              className="mb-1"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={1} className="pt-7">
                            <Button
                              type="default"
                              onClick={() => openMediaPicker("imageAngle")}
                              icon={<PictureOutlined />}
                            />
                            <MediaPicker
                              targetField="imageAngle"
                              isOpen={isMediaPickerOpen.imageAngle}
                              onSelect={onImageSelect}
                              onCancel={() => closeMediaPicker("imageAngle")}
                            />
                          </Col>
                          <Col span={4} className="ml-4">
                            {container?.imageAngle ? (
                              <img
                                src={container.imageAngle}
                                width={80}
                                alt={container.name}
                              />
                            ) : (
                              <Empty
                                description=""
                                imageStyle={{ height: 60 }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={24} className="text-right">
                            <Button type="primary" htmlType="submit">
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Col span={7}>
                      <div className="bg-gray-100 h-full w-full border">
                        <div className="bg-gray-50 p-2 border-b">Tags</div>
                        <div className="bg-white p-2 h-[93.4%]">
                          <Form
                            form={tagsForm}
                            layout="vertical"
                            onFinish={onTagsFormSubmit}
                          >
                            <Form.Item name="menuTags">
                              {isProcessing ? (
                                <Skeleton active />
                              ) : (
                                <Select
                                  size="default"
                                  mode="multiple"
                                  placeholder="Please select"
                                  style={{
                                    width: "100%",
                                  }}
                                  options={menuTags?.map((t) => ({
                                    label: `${t.value}${
                                      t.menuTagGroup?.name
                                        ? ` (${t.menuTagGroup.name})`
                                        : ""
                                    }`,
                                    value: t.id,
                                  }))}
                                  filterOption={filterOption}
                                />
                              )}
                            </Form.Item>
                            <Row gutter={16}>
                              <Col span={24} className="text-right">
                                <Button type="primary" htmlType="submit">
                                  Save Tags
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ),
            },
            {
              key: "2",
              label: "Active/Inactive",
              children: <MenuContainerOverridesList container={container} />,
            },
            {
              key: "3",
              label: "Prices",
              children: <MenuContainerAttributesList container={container} />,
            },
          ]}
        />
      </div>
    </Modal>
  );
}

export default MenuContainerFormModal;
