import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/menu-container-products`;

export const addMenuContainerProduct = async (menuContainerProductPayload) => {
  return axios.post(baseUrl, menuContainerProductPayload);
}

export const updateMenuContainerProduct = async (id, menuContainerProductPayload) => {
  return axios.put(`${baseUrl}/${id}`, menuContainerProductPayload);
}

export const renameMenuContainerProduct = async (existingName, containerIds, newName, imageTop, imageAngle) => {
  return axios.put(`${baseUrl}/rename`, { existingName, containerIds, newName, imageTop, imageAngle });
}

export const upsertMenuContainerProductTags = async (id, menuTagPayload) => {
  return axios.put(`${baseUrl}/${id}/tags`, menuTagPayload);
}


export const fetchMenuContainerProduct = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}



export const fetchMenuContainerProducts = async (q, fieldsArr = [], groupBy) => {
  if (!q) return;
  const params = {
    'filters[name]': q,
    take: 1000
  };
  if (fieldsArr.length) {
    params.fields = fieldsArr
  }

  if (groupBy) {
    params.groupBy = groupBy
  }

  return axios.get(`${baseUrl}`, { params })
}

export const fetchMenuContainerProductTree = async (id) => {
  return axios.get(`${baseUrl}/${id}/tree`);
}

export const deleteMenuContainerProduct = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}