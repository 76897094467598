import React, { useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import useMenuContainerProducts from "../../hooks/useMenuContainerProducts";
import Highlighter from "react-highlight-words";
import useMenuContainers from "../../hooks/useMenuContainers";
import usePosMenus from "../../hooks/usePosMenus";
import { ActionType, Mode, PartType, ProductType } from "../../constants";
import {
  addMenuContainerProduct,
  updateMenuContainerProduct,
} from "../../services/http/menu-container-product.http";
import BooleanIcon from "../common/BooleanIcon";
import MenuContainerProductFormModal from "./MenuContainerProductFormModal";

function MenuContainerProductsList() {
  const [form] = Form.useForm();
  const [allParams, setAllParams] = useState({ take: 10, skip: 0 });
  const { menuContainers } = useMenuContainers({
    take: 1000,
    skip: 0,
  });

  const { menuContainerProducts: parentMenuContainerProducts } =
    useMenuContainerProducts({
      take: 10,
      skip: 0,
    });
  const { posMenus } = usePosMenus({
    take: 1000,
    skip: 0,
  });

  const { menuContainerProducts, meta, isLoading, isError, revalidate } =
    useMenuContainerProducts(allParams);

  const [mode, setMode] = useState(Mode.ADD);
  const [menuContainerProductId, setMenuContainerProductId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMenuContainerProductModalOpen, setIsMenuContainerProductModalOpen] =
    useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAllParams({ ...allParams, [`filters[${dataIndex}]`]: selectedKeys[0] });
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSortProps = (column, sortOrder) => {
    setAllParams({
      ...allParams,
      sortBy: sortOrder === "descend" ? `-${column}` : column,
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        .includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onMenuContainerProductClick = (id) => {
    setMenuContainerProductId(id);
    setIsMenuContainerProductModalOpen(true);
  };

  const columns = [
    {
      title: "Image Top",
      dataIndex: "imageTop",
      key: "imageTop",
      render: (imageTop) =>
        imageTop ? (
          <Popover
            content={<img src={imageTop} alt="imageTop" className="w-72" />}
          >
            <img src={imageTop} alt="imageTop" className="w-12" />
          </Popover>
        ) : (
          <Empty className="w-12 m-0 p-0 h-14 -mt-12" description="" />
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b, sortOrder) => getColumnSortProps("name", sortOrder),
      ...getColumnSearchProps("name"),
      render: (name, { id }) => (
        <Button type="link" onClick={() => onMenuContainerProductClick(id)}>
          {name}
        </Button>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Container",
      dataIndex: ["container", "name"],
      key: "containerId",
    },
    {
      title: "POS PLU",
      dataIndex: "posPlu",
      key: "posPlu",
      sorter: (a, b, sortOrder) => getColumnSortProps("posPlu", sortOrder),
      ...getColumnSearchProps("posPlu"),
    },
    {
      title: "POS Menu",
      dataIndex: ["posMenu", "name"],
      key: "posMenu",
    },

    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text) => <BooleanIcon value={text} />,
    },
  ];

  if (isError)
    <Alert
      message="Error"
      description="Error fetching data"
      type="error"
      showIcon
    />;

  return (
    <>
      <Typography.Title level={3}>Menu Container Products</Typography.Title>
      <Table
        dataSource={menuContainerProducts}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        pagination={{
          total: meta?.totalRows,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          },
        }}
        footer={() => (
          <Typography.Title level={5}>
            Total Records: {meta?.totalRows || "--"}
          </Typography.Title>
        )}
      />
      <MenuContainerProductFormModal
        productId={menuContainerProductId}
        isOpen={isMenuContainerProductModalOpen}
        onClose={() => setIsMenuContainerProductModalOpen(false)}
        onSave={revalidate}
      />
    </>
  );
}

export default MenuContainerProductsList;
