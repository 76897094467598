import { fetcher, apiHost } from '../utils/http'
import useSWR, { mutate } from 'swr'
import { trimEndingSlash } from '../utils'
import { disableRevalidation } from '../constants'

export default function useMenuContainer(id) {
  const trimmedApiHost = trimEndingSlash(apiHost)
  const url = `${trimmedApiHost}/v1/menu-containers/${id}`

  const { data: responseData, error, isLoading } = useSWR(id ? url : null, fetcher, disableRevalidation)

  return {
    menuContainer: responseData,
    isLoading,
    isError: error,
    revalidate: () => mutate(url)
  }
}
