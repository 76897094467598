import { Link } from "react-router-dom";
import RenameProduct from "../components/RenameProduct";
import { Breadcrumb } from "antd";

function RenameProductPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/groups">Groups</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <RenameProduct />
    </div>
  );
}

export default RenameProductPage;
