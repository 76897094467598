import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/menu-templates`;
const menuTemplateSectionBaseUrl = `${apiHost}/v1/menu-template-sections`;

export const addMenuTemplate = async (menuTemplatePayload) => {
  return axios.post(baseUrl, menuTemplatePayload);
}

export const linkTemplateWithMultipleSections = async (menuTemplatePayload) => {
  return axios.post(`${menuTemplateSectionBaseUrl}/bulk-sections`, menuTemplatePayload);
}

export const updateMenuTemplate = async (id, menuTemplatePayload) => {
  return axios.put(`${baseUrl}/${id}`, menuTemplatePayload);
}

export const fetchMenuTemplate = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteMenuTemplate = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}