import { Alert, Button, Form, Modal, Popconfirm, Select, Table } from "antd";
import { useEffect, useState } from "react";

import useMenuContainerProductOverrides from "../../hooks/useMenuContainerProductOverrides";
import {
  addMenuContainerProductOverride,
  deleteMenuContainerProductOverride,
} from "../../services/http/menu-container-product-overrides.http";
import {
  filterOption,
  openNotification,
  selectOptionsMapper,
} from "../../utils";
import { NotificationType } from "../../constants";
import usePosMenus from "../../hooks/usePosMenus";
import useMenuTemplates from "../../hooks/useMenuTemplates";
import useChannels from "../../hooks/useChannels";
import useGroups from "../../hooks/useGroups";
import useStores from "../../hooks/useStores";

function MenuContainerProductOverridesList({ product }) {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [allParams, setAllParams] = useState({
    take: 10,
    skip: 0,
    "filters[menuContainerProductPosPlu]": product?.posPlu,
    "filters[menuContainerPosPlu]": product?.container?.posPlu,
  });

  useEffect(() => {
    setAllParams({
      ...allParams,
      "filters[menuContainerProductPosPlu]": product?.posPlu,
      "filters[menuContainerPosPlu]": product?.container?.posPlu,
    });
  }, [product]);

  const { posMenus } = usePosMenus({
    take: 1000,
    skip: 0,
  });
  const { channels } = useChannels({
    take: 1000,
    skip: 0,
    "filters[isActive]": 1,
  });
  const { groups } = useGroups({
    take: 1000,
    skip: 0,
    "filters[isActive]": 1,
  });
  const { stores } = useStores({
    take: 1000,
    skip: 0,
    "filters[isActive]": 1,
  });
  const { menuTemplates } = useMenuTemplates({
    take: 1000,
    skip: 0,
    "filters[isActive]": 1,
  });

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  const { menuContainerProductOverrides, meta, isLoading, error, revalidate } =
    useMenuContainerProductOverrides(allParams);

  const onDelete = async (id) => {
    try {
      await deleteMenuContainerProductOverride(id);
      revalidate();
      openNotification(NotificationType.Success, "Deleted successfully");
    } catch (error) {
      openNotification(
        NotificationType.Error,
        "Cannot be Deleted",
        error.message
      );
    }
  };

  const columns = [
    {
      key: "Store",
      title: "Store",
      dataIndex: ["store", "name"],
    },
    {
      key: "Channel",
      title: "Channel",
      dataIndex: ["channel", "name"],
    },
    {
      key: "Group",
      title: "Group",
      dataIndex: ["group", "name"],
    },
    {
      key: "posMenu",
      title: "POS Menu",
      dataIndex: ["posMenu", "name"],
    },
    {
      key: "menuTemplate",
      title: "Menu Template",
      dataIndex: ["menuTemplate", "name"],
    },
    {
      key: "Actions",
      title: "Actions",
      width: "10%",
      render: (record) => (
        <Popconfirm title="Are you sure?" onConfirm={() => onDelete(record.id)}>
          <Button type="link" danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];
  const onSubmit = async (values) => {
    try {
      setIsProcessing(true);
      values.menuContainerProductPosPlu = product?.posPlu;
      values.menuContainerPosPlu = product?.container?.posPlu;

      await addMenuContainerProductOverride(values);
      openNotification(NotificationType.Success, "Added successfully");
    } catch (error) {
      openNotification(
        NotificationType.Error,
        "Cannot be Added successfully",
        error.response.data.message
      );
    } finally {
      setIsProcessing(false);
      hideModal();
      revalidate();
    }
  };

  return (
    <div>
      <Alert
        className="mb-4"
        type="info"
        showIcon
        message={
          <div>
            Product Overrides for: <strong>{product?.name}</strong>
          </div>
        }
        description={
          <>
            This product is inactive for the rows shown below.
            <br />
            <span className="font-bold">To deactivate: </span>
            Add a the row by clicking the button below.
            <br />
            <span className="font-bold">To activate: </span>
            Delete the row.
            <br />
          </>
        }
      />

      <Button type="primary" onClick={showModal}>
        Add Override
      </Button>

      <Modal
        title="Add Override"
        open={isModalVisible}
        onOk={form.submit}
        onCancel={hideModal}
        okButtonProps={{ loading: isProcessing }}
      >
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Form.Item label="Channel" name={"channelId"}>
            <Select
              options={selectOptionsMapper(channels, true)}
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item label="Group" name={"groupId"}>
            <Select
              options={selectOptionsMapper(groups, true)}
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item
            label="POS Menu"
            name={"posMenuId"}
            rules={[{ required: true }]}
          >
            <Select
              options={selectOptionsMapper(posMenus, true)}
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item
            label="Menu Template"
            name={"menuTemplateId"}
            rules={[{ required: true }]}
          >
            <Select
              options={selectOptionsMapper(menuTemplates, true)}
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item label="Stores" name={"storeIds"}>
            <Select
              options={selectOptionsMapper(stores, true)}
              mode="multiple"
              filterOption={filterOption}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Table
        size="small"
        dataSource={menuContainerProductOverrides}
        columns={columns}
        loading={isLoading}
        rowKey={(record) => record.id}
        pagination={{
          total: meta?.totalRows,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          },
        }}
      />
    </div>
  );
}

export default MenuContainerProductOverridesList;
