import { Button, Form, Result, Select, Space, Spin, Typography } from "antd";
import React, { useContext, useState } from "react";
import { StepsContext } from "./WorkflowView";

import useChannels from "../../hooks/useChannels";
import useGroups from "../../hooks/useGroups";
import useStores from "../../hooks/useStores";
import useMenuTemplates from "../../hooks/useMenuTemplates";
import useMenuTransformationTemplates from "../../hooks/useMenuTransformationTemplates";
import { filterOption, openNotification } from "../../utils";
import { generateMenu, pollJob } from "../../services/http/menu.http";
import { JobStatus, NotificationType } from "../../constants";

function GenerateMenu() {
  const [form] = Form.useForm();
  const { setCurrentStep } = useContext(StepsContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const { channels, isLoading: isChannelsLoading } = useChannels({
    sortBy: "name",
    take: 1000,
    "filters[isActive]": 1,
  });
  const { groups, isLoading: isGroupsLoading } = useGroups({
    sortBy: "name",
    take: 1000,
    "filters[isActive]": 1,
  });
  const { stores, isLoading: isStoresLoading } = useStores({
    sortBy: "name",
    take: 1000,
    "filters[isActive]": 1,
  });
  const { menuTemplates, isLoading: isMenuTemplatesLoading } = useMenuTemplates(
    {
      sortBy: "name",
      take: 1000,
      "filters[isActive]": 1,
    }
  );

  let interval;

  const onSubmit = async (values) => {
    try {
      setIsProcessing(true);
      const { data } = await generateMenu({
        ...values,
        transformationTemplateId: 2, // Hardcoded for now as per Ricky
      });
      const { id: jobId } = data;

      interval = setInterval(async () => {
        const { data } = await pollJob(jobId, "menu");
        const { status } = data;

        if ([JobStatus.FAILED, JobStatus.COMPLETED].includes(status)) {
          setIsProcessing(false);
          clearInterval(interval);
          if (status === JobStatus.COMPLETED) {
            setIsComplete(true);
          } else {
            setIsFailed(true);
          }
        }
      }, 10000);
    } catch (error) {
      setIsFailed(true);
      setIsProcessing(false);
      openNotification(
        NotificationType.Error,
        "Menu Generation failed",
        `Menu Generation could not be completed. Please try again. ${error.message}`
      );
      console.log(error);
    }
  };

  return (
    <div className="bg-white p-4">
      <Typography.Title level={3}>Generate Menu</Typography.Title>
      <Typography.Text>Generate menu process</Typography.Text>
      <div>
        {isProcessing && (
          <div className="flex flex-col justify-center align-middle">
            <Spin size="large" />
          </div>
        )}
        {isComplete && (
          <Result
            status="success"
            title="Menu Generation Completed"
            subTitle="Menu has been generated successfully"
          />
        )}
        {isFailed && (
          <Result
            status="error"
            title="Menu Generation Failed"
            subTitle="Menu could not be synced, please try again later."
          />
        )}
        {!isProcessing && !isComplete && !isFailed && (
          <div className="mt-5">
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                label="Channel"
                name="channelId"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  loading={isChannelsLoading}
                  placeholder="Select Channel"
                  options={channels?.map((c) => ({
                    label: `${c.name} (${c.id})`,
                    value: c.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label="Group"
                name="groupId"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  loading={isGroupsLoading}
                  placeholder="Select Group"
                  options={groups?.map((c) => ({
                    label: `${c.name} (${c.id})`,
                    value: c.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label="Menu Templates"
                name="menuTemplateId"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  loading={isMenuTemplatesLoading}
                  placeholder="Select Menu Templates"
                  options={menuTemplates?.map((c) => ({
                    label: `${c.name} (${c.id})`,
                    value: c.id,
                  }))}
                />
              </Form.Item>
              {/* <Form.Item
                label="Menu Transformation Templates"
                name="transformationTemplateId"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  loading={isMenuTransformationTemplatesLoading}
                  placeholder="Select Menu Transformation Template"
                  options={menuTransformationTemplates?.map((c) => ({
                    label: `${c.name} (${c.id})`,
                    value: c.id,
                  }))}
                />
              </Form.Item> */}
              <Form.Item
                label="Store"
                name="storeId"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  loading={isStoresLoading}
                  placeholder="Select Store"
                  options={stores?.map((c) => ({
                    label: `${c.name} (${c.id})`,
                    value: c.id,
                  }))}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={isProcessing}>
                {isProcessing ? "Generating..." : "Start Generating Menu"}
              </Button>
            </Form>
          </div>
        )}
      </div>
      <Space className="mt-4">
        <Button type="default" onClick={() => setCurrentStep(0)}>
          Start Over
        </Button>
      </Space>
    </div>
  );
}

export default GenerateMenu;
