import React from "react";
import MenuTagsList from "../components/MenuTag/MenuTagsList";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function MenuTagsListPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/menu-tags">Menu Tags</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <MenuTagsList />
    </div>
  );
}

export default MenuTagsListPage;
