import React from "react";
import GroupsList from "../components/Group/GroupsList";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function GroupsListPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/groups">Groups</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <GroupsList />
    </div>
  );
}

export default GroupsListPage;
