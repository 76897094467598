import {
  Button,
  Result,
  Typography,
  Form,
  Select,
  Row,
  Col,
  Alert,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { StepsContext } from "./WorkflowView";
import useCategories from "../../hooks/useCategories";
import { filterOption, openNotification } from "../../utils";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { toggleStatusCategory } from "../../services/http/category.http";
import { pollJob, syncMenu } from "../../services/http/menu.http";
import usePosMenuConfigurations from "../../hooks/usePosMenuConfigurations";
import { JobStatus, NotificationType } from "../../constants";

function SyncMenu() {
  const { setCurrentStep } = useContext(StepsContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [posMenuId, setPosMenuId] = useState(null);
  const [isFailed, setIsFailed] = useState(false);

  const { categories, isError, isLoading } = useCategories({
    sortBy: "name",
    take: 1000,
    "filters[posMenuId]": posMenuId,
  });

  const {
    posMenuConfigurations,
    isError: isPosMenuConfigurationError,
    isLoading: isPosMenuConfigurationLoading,
  } = usePosMenuConfigurations({
    sortBy: "name",
    take: 1000,
  });

  const [form] = Form.useForm();
  let interval;

  // const jobId = window.localStorage.getItem("syncJobId");

  // useEffect(() => {
  //   if (jobId) {
  //     setIsProcessing(true);
  //     setIsComplete(false);
  //     setIsFailed(false);
  //     pollJobStatus(jobId);
  //   }
  // }, [jobId]);

  const processStatus = async (jobId) => {
    const { data } = await pollJob(jobId, "task");
    const { status } = data;

    if ([JobStatus.FAILED, JobStatus.COMPLETED].includes(status)) {
      setIsProcessing(false);
      clearInterval(interval);
      window.localStorage.removeItem("syncJobId");
      if (status === JobStatus.COMPLETED) {
        setIsComplete(true);
        openNotification(
          NotificationType.Success,
          "Sync Completed",
          "Menu items have been synced successfully"
        );
      } else {
        setIsFailed(true);
        openNotification(
          NotificationType.Error,
          "Sync Failed",
          "Menu items could not be synced "
        );
      }
    }
  };

  const pollJobStatus = async (jobId) => {
    try {
      interval = setInterval(async () => {
        processStatus(jobId);
      }, 10000);
    } catch (error) {
      setIsFailed(true);
      openNotification(
        NotificationType.Error,
        "Sync failed",
        "Sync could not be completed. Please try again."
      );
      clearInterval(interval);
    }
  };

  const onSubmit = async (values) => {
    const { menuId, categoryIds } = values;
    try {
      setIsProcessing(true);
      await toggleStatusCategory({
        isActive: 1,
        categoryIds,
      });
      openNotification(
        NotificationType.Success,
        "Sync Started",
        "Sync Started, it may take a while..."
      );

      const { data } = await syncMenu({ menuId });
      const { id: jobId } = data;

      processStatus(jobId);

      await pollJobStatus(jobId);
      window.localStorage.setItem("syncJobId", jobId);
    } catch (error) {
      setIsFailed(true);
      setIsProcessing(false);
      openNotification(
        NotificationType.Error,
        "Sync failed",
        `Sync could not be completed. Please try again. ${error.message}`
      );
    }
  };

  return (
    <>
      <Typography.Title level={3}>Sync Menu</Typography.Title>
      {isError && (
        <Alert
          message="Error"
          description="Failed to load categories"
          type="error"
        />
      )}
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={24}>
          <Col span={12}>
            <div className="bg-white p-4">
              {isProcessing ? (
                <div className=" flex flex-col h-48 align-middle justify-center">
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  <Form.Item
                    label="Pos Menu"
                    name="menuId"
                    rules={[{ required: true, message: "Field is required" }]}
                  >
                    <Select
                      loading={isPosMenuConfigurationLoading}
                      placeholder="Select POS Menu"
                      filterOption={filterOption}
                      options={posMenuConfigurations?.map((c) => ({
                        label: `${c.name} (${c.posMenuId})`,
                        value: c.posMenuId,
                      }))}
                      className="min-w-40"
                      onSelect={setPosMenuId}
                      showSearch
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label="Categories"
                    name="categoryIds"
                    rules={[{ required: true, message: "Field is required" }]}
                  >
                    <Select
                      mode="multiple"
                      loading={isLoading}
                      placeholder="Select Category"
                      options={categories?.map((c) => ({
                        label: `${c.name}`,
                        value: c.id,
                      }))}
                      filterOption={filterOption}
                      className="min-w-40"
                      showSearch
                      allowClear
                    />
                  </Form.Item>
                </>
              )}
              <div>
                {isComplete ? (
                  <>
                    <Result
                      status="success"
                      title="Sync Completed"
                      subTitle="Menu items have been synced successfully"
                    />
                    <div className="mt-10"></div>
                  </>
                ) : (
                  <div className="mt-6">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isProcessing}
                    >
                      {isProcessing ? "Syncing..." : "Start Sync"}
                    </Button>
                  </div>
                )}

                <Button
                  type="default"
                  className="mt-4"
                  onClick={() => setCurrentStep(1)}
                >
                  Next Step
                </Button>
              </div>
            </div>
          </Col>
          <Col span={2}></Col>
          <Col span={8}>
            <Typography.Title level={4}>
              <InfoCircleTwoTone /> What does it do?
            </Typography.Title>
            <p>
              <ul className="list-decimal">
                <li>It enables these selected categories in db</li>
                <li>Disables categories other than selected</li>
                <li>
                  Starts the phase 1 of the syncs process with the POS system
                </li>
              </ul>
            </p>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SyncMenu;
