import { fetcher, apiHost } from '../utils/http'
import useSWR, { mutate } from 'swr'
import { trimEndingSlash } from '../utils'
import { disableRevalidation } from '../constants'

export default function useMenuTemplate(id) {
  const trimmedApiHost = trimEndingSlash(apiHost)
  const url = `${trimmedApiHost}/v1/menu-templates/${id}`

  const { data: responseData, error, isLoading } = useSWR(id ? url : null, fetcher, disableRevalidation)

  return {
    menuTemplate: responseData,
    meta: responseData?.meta,
    isLoading,
    isError: error,
    revalidate: () => mutate(url)
  }
}
