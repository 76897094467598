import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/menu-container-overrides`;

export const addMenuContainerOverride = async (payload) => {
  return axios.post(baseUrl, payload);
}

export const updateMenuContainerOverride = async (id, payload) => {
  return axios.put(`${baseUrl}/${id}`, payload);
}

export const fetchMenuContainerOverride = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteMenuContainerOverride = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}