import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/menu-containers`;

export const addMenuContainer = async (menuContainerPayload) => {
  return axios.post(baseUrl, menuContainerPayload);
}

export const updateMenuContainer = async (id, menuContainerPayload) => {
  return axios.put(`${baseUrl}/${id}`, menuContainerPayload);
}

export const upsertMenuContainerTags = async (id, menuTagPayload) => {
  return axios.put(`${baseUrl}/${id}/tags`, menuTagPayload);
}

export const fetchMenuContainer = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}
export const fetchMenuContainerTree = async (id) => {
  return axios.get(`${baseUrl}/${id}/tree`);
}


export const fetchMenuContainers = async (q, fieldsArr = []) => {
  if (!q) return;
  const params = {
    'filters[name]': q,
    take: 1000
  };
  if (fieldsArr.length) {
    params.fields = fieldsArr
  }

  return axios.get(`${baseUrl}`, { params })
}


export const deleteMenuContainer = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}