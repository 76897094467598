import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/media`;

export const uploadFile = async (payload) => {
  return axios.post(baseUrl, payload);
}

export const updateMenuContainer = async (id, menuContainerPayload) => {
  return axios.put(`${baseUrl}/${id}`, menuContainerPayload);
}

export const upsertMenuContainerTags = async (id, menuTagPayload) => {
  return axios.put(`${baseUrl}/${id}/tags`, menuTagPayload);
}

export const fetchMenuContainer = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}
export const fetchMenuContainerTree = async (id) => {
  return axios.get(`${baseUrl}/${id}/tree`);
}

export const deleteMenuContainer = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}