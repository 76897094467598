import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./App.css";
import SecureLayout from "./components/common/SecureLayout";
import PosMenuListPage from "./pages/PosMenuListPage";
import CategoriesListPage from "./pages/CategoriesListPage";
import SectionsListPage from "./pages/SectionsListPage";
import MenuContainersListPage from "./pages/MenuContainersListPage";
import MenuContainerProductsListPage from "./pages/MenuContainerProductsListPage";
import { ConfigProvider, theme } from "antd";
import MenuContainersViewPage from "./pages/MenuContainersViewPage";
import TagsListPage from "./pages/TagsListPage";
import MenuTagsListPage from "./pages/MenuTagsListPage";
import ChannelsListPage from "./pages/ChannelsListPage";
import GroupsListPage from "./pages/GroupsListPage";
import MenuTemplatesListPage from "./pages/MenuTemplatesListPage";
import MenuTemplatesViewPage from "./pages/MenuTemplatesViewPage";
import WorkflowPage from "./pages/WorkflowPage";
import SchedulerPage from "./pages/SchedulerPage";
import SyncMenuPage from "./pages/SyncMenuPage";
import GenerateMenuPage from "./pages/GenerateMenuPage";
import MediaCenterPage from "./pages/MediaCenterPage";
import RenameProductPage from "./pages/RenameProductPage";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          algorithm: [theme.darkAlgorithm],
          // Seed Token
          colorPrimary: "#FFD204",
          borderRadius: 2,

          // Alias Token
          // colorBgContainer: "#f6ffed",
        },
      }}
    >
      <div className="App">
        <SecureLayout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pos-menu" element={<PosMenuListPage />} />
            <Route path="/sections" element={<SectionsListPage />} />
            <Route path="/categories" element={<CategoriesListPage />} />
            <Route path="/tags" element={<TagsListPage />} />
            <Route path="/menu-tags" element={<MenuTagsListPage />} />
            <Route path="/channels" element={<ChannelsListPage />} />
            <Route path="/groups" element={<GroupsListPage />} />
            <Route path="/workflow" element={<WorkflowPage />} />
            <Route path="/sync-menu" element={<SyncMenuPage />} />
            <Route path="/generate-menu" element={<GenerateMenuPage />} />
            <Route path="/roster" element={<SchedulerPage />} />
            <Route path="/media-center" element={<MediaCenterPage />} />
            <Route path="/rename-products" element={<RenameProductPage />} />

            <Route
              path="/menu-containers"
              element={<MenuContainersListPage />}
            />
            <Route
              path="/menu-containers/:id"
              element={<MenuContainersViewPage />}
            />
            <Route
              path="/menu-container-products"
              element={<MenuContainerProductsListPage />}
            />
            <Route path="/menu-templates" element={<MenuTemplatesListPage />} />
            <Route
              path="/menu-templates/:id"
              element={<MenuTemplatesViewPage />}
            />
          </Routes>
        </SecureLayout>
      </div>
    </ConfigProvider>
  );
}

export default App;
