import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, Button, Form, Select, Row, Col, Card } from "antd";
import useMenuTemplate from "../../hooks/useMenuTemplate";
import useSections from "../../hooks/useSections";
import { linkTemplateWithMultipleSections } from "../../services/http/menu-template.http";
import { openNotification } from "../../utils";
import useMenuContainers from "../../hooks/useMenuContainers";
import {
  addBulkMenuTemplateSectionContainer,
  fetchMenuTemplateSectionContainerByTemplateSection,
} from "../../services/http/menu-template-section-container.http";
import { nanoid } from "nanoid";
import { NotificationType } from "../../constants";
function MenuTemplateView() {
  const params = useParams();
  const [form] = Form.useForm();
  const [mtscForm] = Form.useForm();
  const { id: menuTemplateId } = params;

  const { menuTemplate, revalidate } = useMenuTemplate(menuTemplateId);
  const { sections } = useSections({ take: 1000, skip: 0 });
  const { menuContainers } = useMenuContainers({
    take: 1000,
    skip: 0,
  });

  const [sectionContainers, setSectionContainers] = useState({});

  // const { menuContainers } = useMenuContainers({
  //   "filters[parentContainerId]": menuTemplateId,
  // });

  useEffect(() => {
    form.setFieldsValue({ sections: menuTemplate?.sections?.map((s) => s.id) });

    if (menuTemplate?.sections?.length > 0) {
      for (const section of menuTemplate?.sections) {
        fetchMenuTemplateSectionContainerByTemplateSection(
          menuTemplateId,
          section?.id
        ).then((res) => {
          setSectionContainers((prev) => ({
            ...prev,
            [section.id]: res.data?.data.map((c) => c.menuContainerPosPlu),
          }));
        });
      }
    }
  }, [menuTemplate]);

  useEffect(() => {
    mtscForm.setFieldsValue(sectionContainers);
  }, [sectionContainers, mtscForm]);

  const onSubmit = async (values) => {
    try {
      await linkTemplateWithMultipleSections({
        menuTemplateId: +menuTemplateId,
        sections: values.sections,
      });

      revalidate();
      openNotification(
        NotificationType.Success,
        "Menu Template linked with Sections successfully"
      );
    } catch (error) {
      console.log(error);
      openNotification(
        NotificationType.Error,
        "Menu Template could not be linked with Sections"
      );
    }
  };
  const onMtscSubmit = async (values) => {
    const vs = [];
    Object.keys(values)
      .filter((k) => values[k])
      .forEach((v) => {
        values[v].forEach((c) => {
          vs.push({
            menuTemplateId: +menuTemplateId,
            menuContainerPosPlu: c,
            sectionId: +v,
          });
        });
      });

    // return;

    try {
      await addBulkMenuTemplateSectionContainer(vs);

      openNotification(
        NotificationType.Success,
        "Menu Template linked with Sections successfully"
      );
    } catch (error) {
      console.log(error);
      openNotification(
        NotificationType.Error,
        "Menu Template could not be linked with Sections"
      );
    }
  };

  return (
    <div>
      <Card>
        <div className="mb-4">
          <h1 className="text-2xl">
            Step 1: Link Sections with Menu Template: {menuTemplate?.name}
          </h1>
          <p className="text-gray-500">
            Select the multiple sections here and save, these sections will
            appear in the section below after you press save.
          </p>
        </div>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item label="Sections" name="sections">
            <Select
              size="large"
              mode="multiple"
              placeholder="Please select"
              style={{
                width: "100%",
              }}
              options={sections?.map((s) => ({
                label: s.name,
                value: s.id,
              }))}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form>
      </Card>

      <Divider />
      <Card>
        <h1 className="text-2xl mb-4">
          Step 2: Link Menu Containers with Menu Template Sections
        </h1>
        <Form form={mtscForm} onFinish={onMtscSubmit}>
          {menuTemplate?.sections?.map((s) => {
            return (
              <Row gutter={16} key={nanoid()}>
                <Col span={2}>{s.name}</Col>
                <Col span={22}>
                  <Form.Item name={s.id}>
                    <Select
                      size="large"
                      mode="multiple"
                      placeholder="Please select"
                      style={{
                        width: "100%",
                      }}
                      options={menuContainers?.map((s) => ({
                        label: s.name,
                        value: s.posPlu,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
          {/* {JSON.stringify(sectionContainers)} */}
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form>
      </Card>
    </div>
  );
}

export default MenuTemplateView;
