import React from "react";
import SyncMenu from "../components/Workflow/SyncMenu";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function SyncMenuPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/sync-menu">Sync Menu</Link> },
  ];
  return (
    <div className="container mx-auto w-[60%]">
      <Breadcrumb className="my-4" items={items} />
      <SyncMenu />
    </div>
  );
}

export default SyncMenuPage;
