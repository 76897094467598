import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/categories`;

export const addCategory = async (categoryPayload) => {
  return axios.post(baseUrl, categoryPayload);
}

export const updateCategory = async (id, categoryPayload) => {
  return axios.put(`${baseUrl}/${id}`, categoryPayload);
}

export const toggleStatusCategory = async (categoryPayload) => {
  return axios.put(`${baseUrl}/toggle-status`, categoryPayload);
}

export const fetchCategory = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteCategory = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}