import React from "react";
import GenerateMenu from "../components/Workflow/GenerateMenu";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function GenerateMenuPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/generate-menu">Generate Menu</Link> },
  ];
  return (
    <div className="container mx-auto w-[60%]">
      <Breadcrumb className="my-4" items={items} />
      <GenerateMenu />
    </div>
  );
}

export default GenerateMenuPage;
