import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/channels`;

export const addChannel = async (channelPayload) => {
  return axios.post(baseUrl, channelPayload);
}

export const updateChannel = async (id, channelPayload) => {
  return axios.put(`${baseUrl}/${id}`, channelPayload);
}

export const fetchChannel = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteChannel = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}