import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/menu-container-product-attributes`;

export const addMenuContainerProductAttributes = async (payload) => {
  return axios.post(baseUrl, payload);
}

export const updateMenuContainerProductAttributes = async (id, payload) => {
  return axios.put(`${baseUrl}/${id}`, payload);
}

export const fetchMenuContainerProductAttributes = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteMenuContainerProductAttributes = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}