import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/tags`;

export const addTag = async (tagPayload) => {
  return axios.post(baseUrl, tagPayload);
}

export const updateTag = async (id, tagPayload) => {
  return axios.put(`${baseUrl}/${id}`, tagPayload);
}

export const fetchTag = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteTag = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}