import React, { useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import useSections from "../../hooks/useSections";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Mode } from "../../constants";
import {
  addSection,
  deleteSection,
  updateSection,
} from "../../services/http/section.http";
import BooleanIcon from "../common/BooleanIcon";

function SectionsList() {
  const [allParams, setAllParams] = useState({ take: 10, skip: 0 });

  const [form] = Form.useForm();
  const [mode, setMode] = useState(Mode.ADD);
  const [sectionId, setSectionId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const { sections, meta, isLoading, isError, revalidate } =
    useSections(allParams);

  const { sections: parentSections } = useSections({ take: 1000, skip: 0 });

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAllParams({ ...allParams, [`filters[${dataIndex}]`]: selectedKeys[0] });
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSortProps = (column, sortOrder) => {
    setAllParams({
      ...allParams,
      sortBy: sortOrder === "descend" ? `-${column}` : column,
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b, sortOrder) => getColumnSortProps("name", sortOrder),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Common Section",
      dataIndex: "isCommonSection",
      key: "isCommonSection",
      render: (text) => <BooleanIcon value={text} />,
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      sorter: (a, b, sortOrder) => getColumnSortProps("name", sortOrder),
      render: (text) => <BooleanIcon value={text} />,
    },
    {
      title: "Display Order",
      dataIndex: "displayOrder",
      key: "displayOrder",
      sorter: (a, b, sortOrder) => getColumnSortProps("name", sortOrder),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              type="text"
              onClick={() => {
                setMode(Mode.EDIT);
                setSectionId(record.id);
                form.setFieldsValue(record);
                setIsFormModalOpen(true);
              }}
              icon={<EditOutlined className="text-xl" />}
            ></Button>
            <Popconfirm
              placement="topRight"
              title={"Delete?"}
              description={"Are you sure to delete?"}
              okText="Yes"
              cancelText="No"
              onConfirm={() => onDeleteSection(record.id)}
            >
              <Button
                type="text"
                className="text-red-500"
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const onDeleteSection = async (id) => {
    try {
      setIsProcessing(true);
      await deleteSection(id);
      revalidate();
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  const onSubmit = async (values) => {
    try {
      setIsProcessing(true);
      if (Mode.ADD === mode) {
        await addSection(values);
      } else {
        await updateSection(sectionId, values);
      }
      setIsProcessing(false);
      setSectionId(null);
      setIsFormModalOpen(false);
      revalidate();
    } catch (error) {
      setIsProcessing(false);
    }
  };

  if (isError)
    <Alert
      message="Error"
      description="Error fetching data"
      type="error"
      showIcon
    />;

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Title level={3}>Sections</Typography.Title>
        </Col>
        <Col span={12} className="text-right">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsFormModalOpen(true)}
          >
            Add Section
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={sections}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        pagination={{
          total: meta?.totalRows,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          },
        }}
        footer={() => (
          <Typography.Title level={5}>
            Total Records: {meta?.totalRows || "--"}
          </Typography.Title>
        )}
      />
      <Modal
        title="Add Section"
        open={isFormModalOpen}
        width={600}
        onOk={form.submit}
        onCancel={() => setIsFormModalOpen(false)}
        okButtonProps={{ loading: isProcessing }}
      >
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Parent Section" name="parentSectionId">
            <Select
              options={parentSections?.map((section) => ({
                key: section.id,
                value: section.id,
                label: section.name,
              }))}
            />
          </Form.Item>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label="Display Order" name="displayOrder">
                <InputNumber className="w-full" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="CommonSection"
                name="isCommonSection"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Active" name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default SectionsList;
