import React from "react";
import MenuContainerProductsList from "../components/MenuContainerProduct/MenuContainerProductsList";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function MenuContainerProductsListPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    {
      title: <Link to="/menu-container-products">Menu Container Products</Link>,
    },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <MenuContainerProductsList />
    </div>
  );
}

export default MenuContainerProductsListPage;
