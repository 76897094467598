import { Button, Col, Row, Space, Steps, Typography } from "antd";
import React, { createContext, useState } from "react";
import SyncMenu from "./SyncMenu";
import MenuContainersList from "../MenuContainer/MenuContainersList";
import GenerateMenu from "./GenerateMenu";

const StepsContext = createContext(1);

function WorkflowView() {
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <div>
      <Typography.Title level={2}>Workflow</Typography.Title>

      <div>
        <StepsContext.Provider value={{ currentStep, setCurrentStep }}>
          <Row gutter={16}>
            <Col span={5}>
              <Steps
                direction="vertical"
                current={currentStep}
                items={[
                  {
                    title: "Sync Menu",
                    description: "Sync the menu with the POS system",
                  },
                  {
                    title: "Update Containers & Products",
                    description: "",
                  },
                  {
                    title: "Generate Menu",
                    description: "Final step, generate the menu items",
                  },
                ]}
              />
            </Col>
            <Col span={19}>
              <div className="">
                {currentStep === 0 && <SyncMenu />}
                {currentStep === 1 && (
                  <div>
                    <MenuContainersList />
                    <div className="mt-10">
                      <Space>
                        <Button
                          type="default"
                          onClick={() => setCurrentStep(currentStep - 1)}
                        >
                          Prev
                        </Button>
                        <Button
                          type="default"
                          onClick={() => setCurrentStep(currentStep + 1)}
                        >
                          Next
                        </Button>
                      </Space>
                    </div>
                  </div>
                )}
                {currentStep === 2 && <GenerateMenu />}
              </div>
            </Col>
          </Row>
        </StepsContext.Provider>
      </div>
    </div>
  );
}

export { WorkflowView, StepsContext };
