import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

function BooleanIcon({ value }) {
  return value === 1 ? (
    <CheckCircleOutlined className="text-xl text-green-500" />
  ) : (
    <CloseCircleOutlined className="text-xl text-red-500" />
  );
}

export default BooleanIcon;
