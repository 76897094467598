import React from "react";
import SectionsList from "../components/Section/SectionsList";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function SectionsListPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/sections">Sections</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <SectionsList />
    </div>
  );
}

export default SectionsListPage;
