import { useRef, useState } from "react";
import {
  Alert,
  Button,
  Empty,
  Input,
  Drawer,
  Popover,
  Space,
  Table,
  Typography,
  Tree,
  Skeleton,
} from "antd";
import {
  ApartmentOutlined,
  DownOutlined,
  FileTextOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import useMenuContainers from "../../hooks/useMenuContainers";
import Highlighter from "react-highlight-words";
import { fetchMenuContainerTree } from "../../services/http/menu-container.http";
import BooleanIcon from "../common/BooleanIcon";
import MenuContainerFormModal from "./MenuContainerFormModal";
import MenuContainerProductFormModal from "../MenuContainerProduct/MenuContainerProductFormModal";

function MenuContainersList() {
  const [allParams, setAllParams] = useState({
    take: 10,
    skip: 0,
    "filters[containerType]": "CT",
  });
  const [menuContainerId, setMenuContainerId] = useState(null);
  const [menuContainerProductId, setMenuContainerProductId] = useState(null);
  const [menuContainer, setMenuContainer] = useState(null);
  const [treeData, setTreeData] = useState();
  const [keys, setKeys] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTreeLoading, setIsTreeLoading] = useState(false);
  const { menuContainers, meta, isLoading, isError, revalidate } =
    useMenuContainers(allParams);

  const [isMenuContainerModalOpen, setIsMenuContainerModalOpen] =
    useState(false);
  const [isMenuContainerProductModalOpen, setIsMenuContainerProductModalOpen] =
    useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAllParams({ ...allParams, [`filters[${dataIndex}]`]: selectedKeys[0] });
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSortProps = (column, sortOrder) => {
    setAllParams({
      ...allParams,
      sortBy: sortOrder === "descend" ? `-${column}` : column,
    });
  };

  const onMenuContainerClick = async (id) => {
    setMenuContainerId(id);
    setIsDrawerOpen(true);
    setIsTreeLoading(true);
    const { data } = await fetchMenuContainerTree(id);
    setMenuContainer(data);
    const tData = {
      title: data.isActive ? data.name : `${data.name} (Inactive)`,
      key: `menuContainer-${data.id}`,
      icon: <ApartmentOutlined />,
      children: [],
    };

    const keys = [];

    if (data.menuContainers) {
      for (const mc of data.menuContainers) {
        const mccChildren = [];
        for (const mcc of mc.menuContainers) {
          const mcpChildren = [];
          for (const mcp of mcc.menuContainerProducts) {
            const mcpChild = {
              key: `menuContainerProduct-${mcp.id}`,
              title: mcp.isActive ? mcp.name : `${mcp.name} (Inactive)`,
              icon: <FileTextOutlined />,
              children: [],
            };
            keys.push(`menuContainerProduct-${mcp.id}`);
            mcpChildren.push(mcpChild);
          }

          const mccChild = {
            key: `menuContainer-${mcc.id}`,
            title: mcc.isActive ? mcc.name : `${mcc.name} (Inactive)`,
            icon: <ApartmentOutlined />,
            children: mcpChildren,
          };
          keys.push(`menuContainer-${mcc.id}`);
          mccChildren.push(mccChild);
        }

        const mcChild = {
          key: `menuContainer-${mc.id}`,
          title: mc.isActive ? mc.name : `${mc.name} (Inactive)`,
          icon: <ApartmentOutlined />,
          children: mccChildren,
        };

        keys.push(`menuContainer-${mc.id}`);
        tData.children.push(mcChild);
        setKeys(keys);
      }
    } else if (data.menuContainerProducts) {
      tData.children = [];
      for (const mcp of data.menuContainerProducts) {
        const mcpChild = {
          key: `menuContainerProduct-${mcp.id}`,
          title: mcp.isActive ? (
            mcp.name
          ) : (
            <Typography.Text type="danger" delete>
              {mcp.name} (Inactive)
            </Typography.Text>
          ),
          icon: <FileTextOutlined />,
          children: [],
        };
        keys.push(`menuContainerProduct-${mcp.id}`);
        tData.children.push(mcpChild);
      }
    }
    setIsTreeLoading(false);

    setTreeData([tData]);
  };

  const onModalSave = () => {
    revalidate();
    onMenuContainerClick(menuContainerId);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image Top",
      dataIndex: "imageTop",
      key: "imageTop",
      render: (imageTop) =>
        imageTop ? (
          <Popover
            content={<img src={imageTop} alt="imageTop" className="w-72" />}
          >
            <img src={imageTop} alt="imageTop" className="w-12" />
          </Popover>
        ) : (
          <Empty className="w-12 m-0 p-0 h-14 -mt-12" description="" />
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b, sortOrder) => getColumnSortProps("name", sortOrder),
      ...getColumnSearchProps("name"),
      render: (name, { id }) => (
        <Button type="link" onClick={() => onMenuContainerClick(id)}>
          {name}
        </Button>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Parent",
      dataIndex: ["parentContainer", "name"],
      key: "parentContainer",
    },
    {
      title: "Container Type",
      dataIndex: "containerType",
      key: "containerType",
    },
    {
      title: "POS PLU",
      dataIndex: "posPlu",
      key: "posPlu",
      sorter: (a, b, sortOrder) => getColumnSortProps("posPlu", sortOrder),
      ...getColumnSearchProps("posPlu"),
    },
    {
      title: "POS Menu",
      dataIndex: ["posMenu", "name"],
      key: "posMenuId",
    },
    {
      title: "POS Menu Flow",
      dataIndex: "posMenuFlowId",
      key: "posMenuFlowId",
    },
    {
      title: "POS Page No",
      dataIndex: "posPageNo",
      key: "posPageNo",
    },

    {
      title: "Common Section",
      dataIndex: "isCommonContainer",
      key: "isCommonContainer",
      render: (text) => <BooleanIcon value={text} />,
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text) => <BooleanIcon value={text} />,
    },
  ];

  if (isError)
    <Alert
      message="Error"
      description="Error fetching data"
      type="error"
      showIcon
    />;

  return (
    <>
      <Typography.Title level={3}>Menu Containers</Typography.Title>
      <Table
        dataSource={menuContainers}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        pagination={{
          total: meta?.totalRows,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          },
        }}
        footer={() => (
          <Typography.Title level={5}>
            Total Records: {meta?.totalRows || "--"}
          </Typography.Title>
        )}
      />
      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        title="Menu Container Detail"
        width={500}
        destroyOnClose
      >
        {isTreeLoading ? (
          <Skeleton active />
        ) : (
          <Tree
            showIcon
            showLine
            switcherIcon={<DownOutlined />}
            treeData={treeData}
            onSelect={(selectedKeys) => {
              if (selectedKeys.length === 0) return;

              const [type, id] = selectedKeys[0].split("-");
              if (type === "menuContainerProduct") {
                setMenuContainerProductId(id);
                setIsMenuContainerProductModalOpen(true);
              } else {
                setMenuContainerId(id);
                setIsMenuContainerModalOpen(true);
              }
            }}
          />
        )}
      </Drawer>
      <MenuContainerFormModal
        containerId={menuContainerId}
        isOpen={isMenuContainerModalOpen}
        onClose={() => setIsMenuContainerModalOpen(false)}
        onSave={onModalSave}
      />
      <MenuContainerProductFormModal
        productId={menuContainerProductId}
        isOpen={isMenuContainerProductModalOpen}
        onClose={() => setIsMenuContainerProductModalOpen(false)}
        onSave={onModalSave}
      />
    </>
  );
}

export default MenuContainersList;
