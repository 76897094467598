import { Breadcrumb } from "antd";
import MenuTemplatesList from "../components/MenuTemplate/MenuTemplatesList";
import { Link } from "react-router-dom";

function MenuTemplatesListPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/menu-templates">Menu Templates</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <MenuTemplatesList />
    </div>
  );
}

export default MenuTemplatesListPage;
