import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Tree,
  Tabs,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { filterOption, openNotification } from "../../utils";
import useMenuContainers from "../../hooks/useMenuContainers";
import useMenuContainerProducts from "../../hooks/useMenuContainerProducts";
import usePosMenus from "../../hooks/usePosMenus";
import {
  fetchMenuContainerProduct,
  updateMenuContainerProduct,
  upsertMenuContainerProductTags,
} from "../../services/http/menu-container-product.http";
import { TbCircleLetterF, TbCircleLetterM } from "react-icons/tb";
import useMenuContainerProduct from "../../hooks/useMenuContainerProduct";
import {
  DownOutlined,
  FileTextOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { fetchMenuContainerProductTree } from "../../services/http/menu-container-product.http";
import useMenuTags from "../../hooks/useMenuTags";
import {
  ActionType,
  ActionTypeMod,
  NotificationType,
  PartType,
  ProductType,
} from "../../constants";
import MenuContainerProductOverridesList from "./MenuContainerProductOverridesList";
import MenuContainerProductAttributesList from "./MenuContainerProductAttributesList";
import MediaPicker from "../MediaCenter/MediaPicker";

function MenuContainerProductFormModal({ productId, isOpen, onClose, onSave }) {
  const [form] = Form.useForm();
  const [tagsForm] = Form.useForm();
  const [isMediaPickerOpen, setIsMediaPickerOpen] = useState({
    imageTop: false,
    imageAngle: false,
  });
  const [product, setProduct] = useState();
  const [isTreeLoading, setIsTreeLoading] = useState(false);
  const [productTags, setProductTags] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [treeData, setTreeData] = useState();
  const [menuContainerProductId, setMenuContainerProductId] = useState();

  useEffect(() => {
    setMenuContainerProductId(productId);
  }, [productId]);

  const { menuContainerProduct, isLoading, isError } =
    useMenuContainerProduct(productId);

  // This is called when the productId changes (on initial load and when a new product is selected from tree)
  useEffect(() => {
    if (menuContainerProductId) {
      fetchProductModifier(menuContainerProductId);
    }
  }, [menuContainerProductId]);

  useEffect(() => {
    if (menuContainerProduct) {
      form.setFieldsValue(menuContainerProduct);
      setIsProcessing(false);
      setProduct(menuContainerProduct);

      fillProductTags(menuContainerProduct);
      if (menuContainerProduct.productType === "P") {
        buildTreeData(menuContainerProduct.id);
      }
    }
  }, [menuContainerProduct]);

  const { menuContainers } = useMenuContainers({
    take: 1000,
    skip: 0,
  });

  const { menuTags } = useMenuTags({
    take: 1000,
    skip: 0,
    sortBy: "value",
  });

  const { menuContainerProducts: parentMenuContainerProducts } =
    useMenuContainerProducts({
      take: 1000,
      skip: 0,
    });
  const { posMenus } = usePosMenus({
    take: 1000,
    skip: 0,
  });

  const fillProductTags = (product) => {
    const menuTags = product.menuTags?.length ? product.menuTags : [];

    tagsForm.setFieldsValue({ menuTags: menuTags.map((mt) => mt.id) });
    setProductTags(menuTags);
  };

  const fetchProductModifier = async (id) => {
    setIsProcessing(true);

    const { data } = await fetchMenuContainerProduct(id);
    form.setFieldsValue(data);
    setProduct(data);
    setIsProcessing(false);
    fillProductTags(data);
    setIsTreeLoading(false);
  };

  const buildTreeData = async (id) => {
    setIsTreeLoading(true);
    const { data } = await fetchMenuContainerProductTree(id);

    const tData = {
      title: data.name,
      key: `menuContainerProduct-${data.id}`,
      icon: <FileTextOutlined />,
    };

    tData.children = [];
    const m = {};

    if (Array.isArray(data?.menuContainerProducts)) {
      for (const mcp of data?.menuContainerProducts) {
        const mcpChild = {
          key: `menuContainerProduct-${mcp.id}`,

          title: mcp.name,
          icon:
            mcp.partType === "F" ? (
              <TbCircleLetterF size={20} />
            ) : (
              <TbCircleLetterM size={20} />
            ),
          children: [],
        };
        const actionTypeFull = ActionTypeMod[mcp.actionType];
        m[actionTypeFull] = m[actionTypeFull] || {
          key: `${actionTypeFull}`,
          title: actionTypeFull,
          // icon: <FileMarkdownOutlined />,
          children: [],
        };
        m[actionTypeFull].children.push(mcpChild);
      }
    }
    const groupedTree = Object.values(m);
    tData.children = groupedTree;

    setIsTreeLoading(false);
    setTreeData([tData]);
  };

  const onSubmit = async (values) => {
    try {
      setIsProcessing(true);
      await updateMenuContainerProduct(productId, values);
      onSave();
      setIsProcessing(false);
      setMenuContainerProductId(null);
      openNotification(
        NotificationType.Success,
        "Success",
        "Menu Container Product saved successfully"
      );
      await buildTreeData(productId);
      onClose();
    } catch (error) {
      setIsProcessing(false);
      openNotification(
        NotificationType.Error,
        "Error",
        `Menu Container Product could not be saved. ${error.message}`
      );
    }
  };

  const onTagsFormSubmit = async (values) => {
    console.info({ productId: menuContainerProductId });
    try {
      setIsProcessing(true);
      await upsertMenuContainerProductTags(menuContainerProductId, {
        menuTags: values.menuTags,
      });
      setIsProcessing(false);
      openNotification(
        NotificationType.Success,
        "Success",
        "Tags saved successfully"
      );
    } catch (error) {
      setIsProcessing(false);
      openNotification(
        NotificationType.Error,
        "Error",
        `Tags could not be saved. ${error.message}`
      );
    }
  };

  const onImageSelect = (field, mediaPath) => {
    form.setFieldValue(field, mediaPath);
    setIsMediaPickerOpen(false);
  };

  const openMediaPicker = (field) => {
    setIsMediaPickerOpen({ ...isMediaPickerOpen, [field]: true });
  };

  const closeMediaPicker = (field) => {
    setIsMediaPickerOpen({ ...isMediaPickerOpen, [field]: false });
  };

  return (
    <Modal
      title={`Menu Container Product (${menuContainerProductId})`}
      open={isOpen}
      width={1200}
      footer={null}
      onCancel={onClose}
      okButtonProps={{ loading: isProcessing }}
      destroyOnClose
      // centered
      style={{
        top: 20,
      }}
    >
      {isError && (
        <Alert
          description="Error fetching data"
          message="Error"
          showIcon
          type="error"
        />
      )}
      {isLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <div>
          <Tabs
            defaultActiveKey="1"
            style={{
              marginBottom: 32,
            }}
            type="card"
            items={[
              {
                key: "1",
                label: "Menu Container Product",
                children: (
                  <Row gutter={16}>
                    <Col span={5}>
                      <div className="bg-gray-100 h-full w-full border">
                        <div className="bg-gray-50 p-2 border-b">
                          Product & Modifiers
                        </div>
                        <div className="bg-white p-2 h-[593px] overflow-auto">
                          {isTreeLoading ? (
                            <Skeleton active />
                          ) : treeData?.[0].title ? (
                            <Tree
                              showIcon
                              showLine
                              defaultExpandedKeys={[
                                `menuContainerProduct-${productId}`,
                                "DEFAULT",
                              ]}
                              switcherIcon={<DownOutlined />}
                              treeData={treeData}
                              onSelect={(selectedKeys) => {
                                if (selectedKeys.length === 0) return;

                                const [type, id] = selectedKeys[0].split("-");
                                if (type === "menuContainerProduct") {
                                  setMenuContainerProductId(id);
                                }
                              }}
                            />
                          ) : (
                            <Empty />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col span={13}>
                      {!isProcessing ? (
                        <Form form={form} layout="vertical" onFinish={onSubmit}>
                          <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item label="Description" name="description">
                            <Input />
                          </Form.Item>
                          <Form.Item label="Allergen Info" name="allergenInfo">
                            <Input />
                          </Form.Item>
                          <Row gutter={8}>
                            <Col span={6}>
                              <Form.Item
                                label="Parent Container"
                                name="containerId"
                                rules={[{ required: true }]}
                              >
                                <Select
                                  disabled
                                  options={menuContainers?.map((container) => ({
                                    value: container.id,
                                    label: container.name,
                                  }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="POS Menu"
                                name="posMenuId"
                                rules={[{ required: true }]}
                              >
                                <Select
                                  disabled
                                  options={posMenus?.map((posMenu) => ({
                                    value: posMenu.id,
                                    label: posMenu.name,
                                  }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Parent Product"
                                name="parentProductId"
                              >
                                <Select
                                  disabled
                                  options={parentMenuContainerProducts?.map(
                                    (parentContainer) => ({
                                      value: parentContainer.id,
                                      label: parentContainer.name,
                                    })
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="POS Menu flow"
                                name="posMenuFlowId"
                              >
                                <InputNumber className="w-full" disabled />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={16}>
                            <Col span={6}>
                              <Form.Item
                                label="POS PLU"
                                name="posPlu"
                                rules={[{ required: true }]}
                              >
                                <InputNumber className="w-full" disabled />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Display Order"
                                name="displayOrder"
                              >
                                <InputNumber className="w-full" />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item label="Modifier Id" name="modifierId">
                                <InputNumber className="w-full" />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Active"
                                name="isActive"
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={6}>
                              <Form.Item
                                label="Product Type"
                                name="productType"
                              >
                                <Select
                                  disabled
                                  options={Object.keys(ProductType).map(
                                    (key) => ({
                                      value: ProductType[key],
                                      label: key,
                                    })
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item label="Part Type" name="partType">
                                <Select
                                  disabled
                                  options={Object.keys(PartType).map((key) => ({
                                    value: PartType[key],
                                    label: key,
                                  }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item label="Action Type" name="actionType">
                                <Select
                                  disabled
                                  options={Object.keys(ActionType).map(
                                    (key) => ({
                                      value: ActionType[key],
                                      label: key,
                                    })
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item label="Kilojoules" name="kilojoules">
                                <InputNumber />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={8} className="mb-5">
                            <Col span={17}>
                              <Form.Item
                                label="Top Image"
                                name="imageTop"
                                className="mb-1"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={1} className="pt-7">
                              <Button
                                type="default"
                                onClick={() => openMediaPicker("imageTop")}
                                icon={<PictureOutlined />}
                              />
                              <MediaPicker
                                targetField="imageTop"
                                isOpen={isMediaPickerOpen.imageTop}
                                onSelect={onImageSelect}
                                onCancel={() => closeMediaPicker("imageTop")}
                              />
                            </Col>
                            <Col span={4}>
                              {product?.imageTop ? (
                                <img
                                  src={product.imageTop}
                                  width={60}
                                  alt={product.name}
                                />
                              ) : (
                                <Empty
                                  description=""
                                  imageStyle={{ height: 60 }}
                                />
                              )}
                            </Col>
                          </Row>
                          <Row gutter={8}>
                            <Col span={17}>
                              <Form.Item
                                label="Angle Image"
                                name="imageAngle"
                                className="mb-1"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={1} className="pt-7">
                              <Button
                                type="default"
                                onClick={() => openMediaPicker("imageAngle")}
                                icon={<PictureOutlined />}
                              />
                              <MediaPicker
                                targetField="imageAngle"
                                isOpen={isMediaPickerOpen.imageAngle}
                                onSelect={onImageSelect}
                                onCancel={() => closeMediaPicker("imageAngle")}
                              />
                            </Col>
                            <Col span={4}>
                              {product?.imageAngle ? (
                                <img
                                  src={product.imageAngle}
                                  width={80}
                                  alt={product.name}
                                />
                              ) : (
                                <Empty
                                  description=""
                                  imageStyle={{ height: 60 }}
                                />
                              )}
                            </Col>
                          </Row>

                          <Row gutter={16}>
                            <Col span={24} className="text-right">
                              <Button type="primary" htmlType="submit">
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      ) : (
                        <>
                          <Skeleton active />
                          <Skeleton active />
                          <Skeleton active />
                          <Skeleton active />
                          <Skeleton active />
                        </>
                      )}
                    </Col>
                    <Col span={6}>
                      <div className="bg-gray-100 h-full w-full border">
                        <div className="bg-gray-50 p-2 border-b">Tags</div>
                        <div className="bg-white p-2 h-[94.3%]">
                          <Form
                            form={tagsForm}
                            layout="vertical"
                            onFinish={onTagsFormSubmit}
                          >
                            <Form.Item name="menuTags">
                              {isProcessing ? (
                                <Skeleton active />
                              ) : (
                                <Select
                                  size="default"
                                  mode="multiple"
                                  placeholder="Please select"
                                  style={{
                                    width: "100%",
                                  }}
                                  options={menuTags?.map((t) => ({
                                    label: `${t.value}${
                                      t.menuTagGroup?.name
                                        ? ` (${t.menuTagGroup.name})`
                                        : ""
                                    }`,
                                    value: t.id,
                                  }))}
                                  filterOption={filterOption}
                                />
                              )}
                            </Form.Item>
                            <Row gutter={16}>
                              <Col span={24} className="text-right">
                                <Button type="primary" htmlType="submit">
                                  Save Tags
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                          <Divider />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ),
              },
              {
                key: "2",
                label: "Active/Inactive",
                children: (
                  <MenuContainerProductOverridesList product={product} />
                ),
              },
              {
                key: "3",
                label: "Prices",
                children: (
                  <MenuContainerProductAttributesList product={product} />
                ),
              },
            ]}
          />
        </div>
      )}
    </Modal>
  );
}

export default MenuContainerProductFormModal;
