import React from "react";
import PosMenuList from "../components/PosMenu/PosMenuList";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

function PosMenuListPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/pos-menu">POS Menu</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <PosMenuList />
    </div>
  );
}

export default PosMenuListPage;
