
export const Mode = {
  ADD: 'add',
  EDIT: 'edit'
}

export const ProductType = {
  PRODUCT: 'P',
  MODIFIER: 'M'
}

export const PartType = {
  FILLING: 'F',
  EXTRA: 'E'
}

export const ActionType = {
  ADD: 'A',
  EXTRA: 'E',
  DEFAULT: 'D',
  REMOVE: 'R',
}

export const ActionTypeMod = {
  [ActionType.ADD]: 'ADD',
  [ActionType.DEFAULT]: 'DEFAULT',
  [ActionType.EXTRA]: 'EXTRA',
  [ActionType.REMOVE]: 'REMOVE',
}

export const NotificationType = {
  Success: 'success',
  Error: 'error'
}

export const JobStatus = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  INPROGRESS: 'INPROGRESS'
}

export const disableRevalidation = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
}