import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/groups`;

export const addGroup = async (groupPayload) => {
  return axios.post(baseUrl, groupPayload);
}

export const updateGroup = async (id, groupPayload) => {
  return axios.put(`${baseUrl}/${id}`, groupPayload);
}

export const fetchGroup = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteGroup = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}