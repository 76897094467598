import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Flex,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useCallback, useState } from "react";
import dayjs from "dayjs";
import ButtonGroup from "antd/es/button/button-group";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import Job from "../components/Job/Job";
import SortableJob from "../components/Job/SortableJob";

const users = [
  {
    name: "John Doe",
    id: 1,
    avatar: "https://i.pravatar.cc/150?img=1",
  },
  {
    name: "Jane Doe",
    id: 2,
    avatar: "https://i.pravatar.cc/150?img=2",
  },
  {
    name: "John Smith",
    id: 3,
    avatar: "https://i.pravatar.cc/150?img=3",
  },
  {
    name: "Jane Smith",
    id: 4,
    avatar: "https://i.pravatar.cc/150?img=4",
  },
  {
    name: "John Johnson",
    id: 5,
    avatar: "https://i.pravatar.cc/150?img=5",
  },
  {
    name: "Jane Johnson",
    id: 6,
    avatar: "https://i.pravatar.cc/150?img=6",
  },
];

const clients = [
  {
    name: "Client 1",
    id: 1,
    avatar: "https://i.pravatar.cc/150?img=7",
  },
  {
    name: "Client 2",
    id: 2,
    avatar: "https://i.pravatar.cc/150?img=8",
  },
  {
    name: "Client 3",
    id: 3,
    avatar: "https://i.pravatar.cc/150?img=9",
  },
];

const days = [
  {
    name: "Monday",
    id: 1,
    date: "2024-10-14",
  },
  {
    name: "Tuesday",
    id: 2,
    date: "2024-10-15",
  },
  {
    name: "Wednesday",
    id: 3,
    date: "2024-10-16",
  },
  {
    name: "Thursday",
    id: 4,
    date: "2024-10-17",
  },
  {
    name: "Friday",
    id: 5,
    date: "2024-10-18",
  },
  {
    name: "Saturday",
    id: 6,
    date: "2024-10-19",
  },
  {
    name: "Sunday",
    id: 7,
    date: "2024-10-20",
  },
];

const jobs = [
  {
    user: 1,
    startTime: "2024-10-14T09:00:00",
    endTime: "2024-10-14T10:00:00",
    type: "Restaurant",
  },
  {
    user: 2,
    startTime: "2024-10-15T09:00:00",
    endTime: "2024-10-15T10:00:00",
    type: "Hola Central",
  },
  {
    user: 2,
    startTime: "2024-10-15T11:00:00",
    endTime: "2024-10-15T11:30:00",
    type: "Hola Central",
  },
];

const stores = [
  {
    name: "Store 1",
    id: 1,
  },
  {
    name: "Store 2",
    id: 2,
  },
  {
    name: "Store 3",
    id: 3,
  },
];

function SchedulerPage() {
  const [items, setItems] = useState(jobs);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [activeId, setActiveId] = useState(null);

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);
  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }, []);
  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <Drawer
          styles={{ header: { backgroundColor: "#f9f9f9" } }}
          placement="bottom"
          closable={false}
          open={true}
          height={"94%"}
          title={
            <Row gutter={16}>
              <Col span={12}>
                <Space size={"large"}>
                  <Radio.Group defaultValue="user" buttonStyle="solid">
                    <Radio.Button value="user">User</Radio.Button>
                    <Radio.Button value="client">Client</Radio.Button>
                  </Radio.Group>
                  <ButtonGroup>
                    <Button>&larr;</Button>
                    <Button>Today</Button>
                    <Button>&rarr;</Button>
                  </ButtonGroup>
                </Space>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="Select Store"
                  className="w-40"
                  options={stores.map((store) => ({
                    label: store.name,
                    value: store.id,
                  }))}
                />
              </Col>
              <Col span={6}>
                <div>
                  <DatePicker mode="week" picker="week" />
                </div>
              </Col>
            </Row>
          }
        >
          <div className="max-h-screen overflow-auto relative">
            <div className="sticky top-0 left-0 right-0 bg-white z-10">
              <Row gutter={0}>
                <Col span={3}>
                  <div className={`border p-2 min-h-16 border-b-0 bg-white`}>
                    <Input.Search placeholder="Search" size="large" />
                  </div>
                </Col>
                {days.map((day, index) => (
                  <Col key={day.id} span={3}>
                    <div
                      className={`border p-2 min-h-16 bg-white ${
                        index < days.length - 1 ? "border border-l-0" : ""
                      } `}
                    >
                      <div className="text-center">
                        <p className="text-lg mb-0">{day.name}</p>
                        <p className="text-xs text-gray-500 mt-0">
                          {dayjs(day.date).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            {users.map((user, index) => (
              <Row key={user.id} gutter={0}>
                <Col
                  span={3}
                  className={`border p-2  min-h-16 ${
                    index < users.length - 1 ? "border-b-0" : ""
                  }`}
                >
                  <div>
                    <Flex justify="left" gap={6}>
                      <Avatar src={user.avatar} size={"large"} />
                      <div>
                        <p className="text-lg m-0">{user.name}</p>
                        <p className="text-xs text-gray-500 m-0">User</p>
                      </div>
                    </Flex>
                  </div>
                </Col>
                <SortableContext items={days} strategy={rectSortingStrategy}>
                  {days.map((day, dIndex) => (
                    <Col
                      key={day.id}
                      span={3}
                      className={`border px-1 flex flex-col min-h-16 ${
                        dIndex <= days.length - 1 ? "border-l-0" : ""
                      } ${index < users.length - 1 ? "border-b-0" : ""}
                  ${
                    ["Sat", "Sun"].includes(dayjs(day.date).format("ddd"))
                      ? "bg-gray-50"
                      : ""
                  } 
                `}
                    >
                      <div>
                        {jobs.map((job) => {
                          if (job.user === user.id) {
                            if (
                              dayjs(job.startTime).format("YYYY-MM-DD") ===
                              day.date
                            ) {
                              return (
                                <SortableJob
                                  key={job.id}
                                  id={job.id}
                                  job={job}
                                  clients={clients}
                                />
                              );
                            }
                          }
                        })}
                      </div>
                    </Col>
                  ))}
                </SortableContext>
                <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
                  {activeId ? <Job id={activeId} isDragging /> : null}
                </DragOverlay>
              </Row>
            ))}
          </div>
        </Drawer>
      </DndContext>
    </div>
  );
}

export default SchedulerPage;
