import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/menu-container-attributes`;

export const addMenuContainerAttributes = async (payload) => {
  return axios.post(baseUrl, payload);
}

export const updateMenuContainerAttributes = async (id, payload) => {
  return axios.put(`${baseUrl}/${id}`, payload);
}

export const fetchMenuContainerAttributes = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const deleteMenuContainerAttributes = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}