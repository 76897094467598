import axios from "axios";
import { apiHost } from "../../utils/http";

const baseUrl = `${apiHost}/v1/menu-template-section-containers`;

export const addBulkMenuTemplateSectionContainer = async (menuContainerPayload) => {
  return axios.post(`${baseUrl}/bulk`, menuContainerPayload);
}

export const addMenuTemplateSectionContainer = async (menuContainerPayload) => {
  return axios.post(baseUrl, menuContainerPayload);
}

export const updateMenuTemplateSectionContainer = async (id, menuContainerPayload) => {
  return axios.put(`${baseUrl}/${id}`, menuContainerPayload);
}

export const fetchMenuTemplateSectionContainer = async (id) => {
  return axios.get(`${baseUrl}/${id}`);
}

export const fetchMenuTemplateSectionContainerByTemplateSection = async (templateId, sectionId) => {
  return axios.get(`${baseUrl}/by-template-section?templateId=${templateId}&sectionId=${sectionId}`);
}

export const deleteMenuTemplateSectionContainer = async (id) => {
  return axios.delete(`${baseUrl}/${id}`);
}