import { fetcher, apiHost } from '../utils/http'
import useSWR, { mutate } from 'swr'
import { trimEndingSlash } from '../utils'
import { disableRevalidation } from '../constants'

export default function useMenuContainerProduct(id) {
  const trimmedApiHost = trimEndingSlash(apiHost)
  const url = `${trimmedApiHost}/v1/menu-container-products`

  const { data: responseData, error, isLoading } = useSWR(id ? `${url}/${id}` : null, fetcher, disableRevalidation)

  return {
    menuContainerProduct: responseData,
    isLoading,
    isError: error,
    revalidate: () => mutate(`${url}/${id}`)
  }
}
