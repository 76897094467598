import {
  Modal,
  Typography,
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Upload,
  Popover,
  Empty,
  Alert,
  Table,
  Space,
} from "antd";
import React, { useRef, useState } from "react";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { uploadFile } from "../../services/http/media.http";
import Highlighter from "react-highlight-words";
import useMedia from "../../hooks/useMedia";

function MediaCenterList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allParams, setAllParams] = useState({
    take: 10,
    skip: 0,
  });
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const { images, meta, isLoading, isError, revalidate } = useMedia(allParams);

  const onSubmit = async (values) => {
    console.log(values);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("posPlu", values.posPlu);
    setIsUploading(true);

    try {
      await uploadFile(formData);
      setFileList([]);
      form.resetFields();
      setIsModalOpen(false);
      revalidate();
      message.success("upload successfully.");
    } catch (error) {
      message.error("upload failed.");
    } finally {
      setIsUploading(false);
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAllParams({ ...allParams, [`filters[${dataIndex}]`]: selectedKeys[0] });
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSortProps = (column, sortOrder) => {
    setAllParams({
      ...allParams,
      sortBy: sortOrder === "descend" ? `-${column}` : column,
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "30%",
    },
    {
      title: "Image",
      dataIndex: "url",
      key: "url",
      render: (url) =>
        url ? (
          <Popover content={<img src={url} alt="url" className="w-72" />}>
            <img src={url} alt="url" className="w-12" />
          </Popover>
        ) : (
          <Empty className="w-12 m-0 p-0 h-14 -mt-12" description="" />
        ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      height: "100px",
      ...getColumnSearchProps("url"),
      render: (url) => (
        <a href={url} rel="noreferrer" target="_blank">
          {url}
        </a>
      ),
    },
    {
      title: "PosPlu",
      dataIndex: "posPlu",
      key: "posPlu",
      sorter: (a, b, sortOrder) => getColumnSortProps("posPlu", sortOrder),
      ...getColumnSearchProps("posPlu"),
    },
  ];

  if (isError)
    <Alert
      message="Error"
      description="Error fetching data"
      type="error"
      showIcon
    />;

  return (
    <div>
      <Typography.Title level={3}>Media Center List</Typography.Title>
      <Row gutter={"12"}>
        <Col span={12}></Col>
        <Col span={12} className="text-right">
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            Upload new media
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={images}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        pagination={{
          total: meta?.totalRows,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          },
        }}
        footer={() => (
          <Typography.Title level={5}>
            Total Records: {meta?.totalRows || "--"}
          </Typography.Title>
        )}
      />
      <Modal
        title="Upload new media"
        open={isModalOpen}
        onOk={form.submit}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Form.Item label="POS PLU" name="posPlu" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Image" name="image" rules={[{ required: true }]}>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default MediaCenterList;
