import React from "react";
import TagsList from "../components/Tag/TagsList";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

function TagsListPage() {
  const items = [
    { title: <Link to="/">Home</Link> },
    { title: <Link to="/tags">Tags</Link> },
  ];
  return (
    <div>
      <Breadcrumb className="my-4" items={items} />
      <TagsList />
    </div>
  );
}

export default TagsListPage;
