import { fetcher, apiHost } from '../utils/http'
import useSWR, { mutate } from 'swr'
import { trimEndingSlash } from '../utils'
import { disableRevalidation } from '../constants'

export default function useCategories(params) {
  const trimmedApiHost = trimEndingSlash(apiHost)
  const url = `${trimmedApiHost}/v1/categories`

  const { data: responseData, error, isLoading } = useSWR([url, params], fetcher, disableRevalidation)

  return {
    categories: responseData?.data || [],
    meta: responseData?.meta,
    isLoading,
    isError: error,
    revalidate: () => mutate([url, params])
  }
}
