import { Link, useParams } from "react-router-dom";
import useMenuContainer from "../../hooks/useMenuContainer";
import { Card, Divider, Row, Col, List } from "antd";
import useMenuContainers from "../../hooks/useMenuContainers";
import { nanoid } from "nanoid";

function MenuContainerView() {
  const params = useParams();
  const { id: menuContainerId } = params;

  const { menuContainer } = useMenuContainer(menuContainerId);
  const { menuContainers } = useMenuContainers({
    "filters[parentContainerId]": menuContainerId,
    "filters[menuContainerProducts]": "P",
    "relations[menuContainerProducts]": true,
  });
  const { menuContainers: mtc } = useMenuContainers({
    "filters[parentContainerId]": menuContainerId,
  });

  return (
    <div>
      <h1 className="text-2xl">Menu Container: {menuContainer?.name}</h1>
      <Divider />

      <Row gutter={12}>
        <Col span={6}>
          <Card title="Sub Containers" type="inner">
            {mtc.map((mt) => {
              return (
                <Link to={`/menu-containers/${mt.id}`} key={nanoid()}>
                  {mt.name}
                </Link>
              );
            })}
            {menuContainers?.map((container) => (
              <Card
                title={container.name}
                key={nanoid()}
                className="mb-4"
                styles={{ body: { padding: 0 } }}
              >
                <List size="small">
                  {container.menuContainerProducts?.map((p) => (
                    <List.Item key={nanoid()}>{p.name}</List.Item>
                  ))}
                </List>
              </Card>
            ))}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MenuContainerView;
